import CashbackProducts from './features/Cashback/CashbackProducts';
import CashbackShops from './features/Cashback/CashbackShops';
import CashbackExtension from './features/CashbackExtension/CashbackExtension';
import ClickoutHint from './features/ClickoutHint/ClickoutHint';
import Cashouts from './features/Dashboard/Cashouts';
import ChangeCredentials from './features/Dashboard/ChangeCredentials';
import ChangeInterests from './features/Dashboard/ChangeInterests';
import Complaints from './features/Dashboard/Complaints';
import CreateComplaint from './features/Dashboard/CreateComplaint';
import Dashboard from './features/Dashboard/Dashboard';
import Settings from './features/Dashboard/Settings';
import Transactions from './features/Dashboard/Transactions';
import TransactionsOverview from './features/Dashboard/TransactionsOverview';
import DataProtection from './features/DataProtection/DataProtection';
import Disclaimer from './features/Disclaimer/Disclaimer';
import DocumentsAndFormulars from './features/DocumentsAndFormulars/DocumentsAndFormulars';
import Imprint from './features/Imprint/Imprint';
import Login from './features/Login';
import { Logout } from './features/Logout';
import PlusServices from './features/PlusServices/PlusServices';
import Registration from './features/Registration/Registration';
import ResetPassword from './features/ResetPassword';
import Security from './features/Security/Security';
import Special from './features/Special/Special';
import Terms from './features/Terms/Terms';
import Tickets from './features/Tickets/Tickets';
import Travel from './features/Travel/Travel';
import TravelBooking from './features/Travel/TravelBooking';

export interface Route {
  title: string;
  link: string;
  component?: any;
  infoPage?: { route: string; content: any };
  private?: boolean;
}

export const navigationItems: Route[] = [
  {
    title: "Reise",
    link: "/reise",
    component: Travel,
  },
  {
    title: "Shops & Produkte",
    link: "/cashback/shops",
    component: CashbackShops,
  },
  {
    title: "Cashback-Highlights",
    link: "/angebote",
    component: Special,
  },
  {
    title: "Tickets",
    link: "/tickets",
    component: Tickets,
  },
  {
    title: "Sicherheit",
    link: "/sicherheit",
    component: Security,
  },
  {
    title: "Plus-Leistungen",
    link: "/plus-leistungen",
    component: PlusServices,
  },
];

export const footerItems: Route[] = [
  {
    title: "Impressum",
    link: "/impressum",
    component: Imprint,
  },
  {
    title: "Haftungsausschluss",
    link: "/haftungsausschluss",
    component: Disclaimer,
  },
  {
    title: "Datenschutz",
    link: "/datenschutz",
    component: DataProtection,
  },
  {
    title: "AGB",
    link: "/agb",
    component: Terms,
  },
];

export const hiddenRoutes: Route[] = [
  {
    title: "Login",
    link: "/login",
    component: Login,
  },
  {
    title: "Cashbackradar",
    link: "/cashbackradar",
    component: CashbackExtension,
  },
  {
    title: "Dokumente & Formulare",
    link: "/dokumente-und-formulare",
    component: DocumentsAndFormulars,
    private: true,
  },
  {
    title: "Nachbuchungsanfragen",
    link: "/nachbuchungsanfragen",
    component: Complaints,
    private: true,
  },
  {
    title: "Auszahlungen",
    link: "/auszahlungen",
    component: Cashouts,
    private: true,
  },
  {
    title: "Transaktionen",
    link: "/transaktionen",
    component: Transactions,
    private: true,
  },
  {
    title: "Transaktionen",
    link: "/transaktionen/uebersicht",
    component: TransactionsOverview,
    private: true,
  },
  {
    title: "Registrierung",
    link: "/registrierung",
    component: Registration,
  },
  {
    title: "Profil",
    link: "/profil",
    component: Dashboard,
    private: true,
  },
  {
    title: "nachbuchungsanfrage",
    link: "/nachbuchungsanfrage",
    component: CreateComplaint,
    private: true,
  },
  {
    title: "ihre-zugangsdaten",
    link: "/ihre-zugangsdaten",
    component: ChangeCredentials,
    private: true,
  },
  {
    title: "ihre-interessen",
    link: "/ihre-interessen",
    component: ChangeInterests,
    private: true,
  },
  {
    title: "ihre-daten",
    link: "/ihre-daten",
    component: Settings,
    private: true,
  },

  {
    title: "Abmelden",
    link: "/abmelden",
    component: Logout,
    private: true,
  },
  {
    title: "Passwort zurücksetzen",
    link: "/passwort-vergessen",
    component: ResetPassword,
    private: false,
  },
  {
    title: "CashbackProducts",
    link: "/cashback/produkte",
    component: CashbackProducts,
  },
  {
    title: "Reisebuchung",
    link: "/reise/buchung",
    component: TravelBooking,
  },
  {
    title: "Clickout-Hinweis",
    link: "/clickout",
    component: ClickoutHint,
  },
];
